<template id="inventario-historico">
<div class="page-inventario-historico">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Inventario Historico" class="elevation-1 px-5 py-3">
          <div class="contenedor-tab-dev">
            <v-card-text>
              <v-card-title>
                <span class="headline"></span>
              </v-card-title>
              <v-form ref="form1" lazy-validation>
                <v-container grid-list-md text-xs-center>
                  <v-row>
                    <v-col sm='12' md='6' lg='6'>
                      <v-menu offset-x ref="menu_fecha" v-model="menu_fecha" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                        <template v-slot:activator="{ on }">
                          <v-text-field v-on="on" v-model="fecha_historico" label="Fecha" color="secondary" prepend-icon="event" @blur="fecha_historico = parseDate(fecha_historico)" :rules="[rules.required]">
                          </v-text-field>
                        </template>

                        <v-date-picker v-model="fecha_historico" color="secondary" scrollable locale="es-mx" @input="menu_fecha = false">
                          <v-spacer />

                          <v-btn color="secondary" x-small @click="menu_fecha = false">
                            Cancelar
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col sm='12' md='6' lg='6'>
                      
                          <v-btn color="info" small @click="buscar">
                            <v-icon>search</v-icon>Vista Previa
                          </v-btn>
                        
                    </v-col>
                    
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-text>
              <div id="datagrid">

                <v-client-table ref="vuetable" :data="items" :columns="columns" :options="options" class="elevation-2 pa-2">                  
                  <template slot="beforeTable">
                  <div style="text-align:right" v-show="items.length>0">
                   <exportar-excel :data="items" :exportFields="encabezadoLayout" name="InventarioHistorico.xlsx">
                            <v-btn id="botonExportarArticulos" dark small color="green darken-2" title="Exportar a Excel" v-tippy>
                              <v-icon dark>mdi-file-excel</v-icon> Exportar a Excel
                            </v-btn>
                          </exportar-excel>
                  </div><br>
                </template>

                  <template slot="noResults">
                    <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                  </template>                  
                  <template slot="existencia_total" slot-scope="props">
                    <div style="text-align:center">{{ formatNumberDecCero(props.row.existencia_total,2) }}</div>
                  </template>
                  <template slot="existencia_sucursal" slot-scope="props">
                    <div style="text-align:center">{{ formatNumberDecCero(props.row.existencia_sucursal,2) }}</div>
                  </template>
                  <template slot="precio_compra" slot-scope="props">
                    <div style="text-align:right">${{ formatNumberDec(props.row.precio_compra,2) }}</div>
                  </template>
                  <template slot="valor" slot-scope="props">
                    <div style="text-align:right">${{ formatNumberDec(props.row.valor,2) }}</div>
                  </template>
                  <template slot="precio_compra_con_iva" slot-scope="props">
                    <div style="text-align:right">${{ formatNumberDec(props.row.precio_compra_con_iva,2) }}</div>
                  </template>
                  <template slot="valor_con_iva" slot-scope="props">
                    <div style="text-align:right">${{ formatNumberDec(props.row.valor_con_iva,2) }}</div>
                  </template>
                  

                  <template slot="actions" slot-scope="props">
                    <v-btn x-small fab dark color="green" @click.native="get_detalle(props.row.sucursales)" title="Ver Detalle" v-tippy>
                        <v-icon>list</v-icon>
                      </v-btn>
                  </template>
                </v-client-table>                
              </div>
            </v-card-text>
          </div>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- MODAL ARTICULOS -->
  <v-dialog v-model="modal_detalle" max-width="40%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">add</v-icon>&nbsp;
          <span class="subheading">
            <strong>Detalle de Inventario</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_detalle = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-row>
            <v-col>
              <v-client-table ref="vuetablearticulos" :data="lote_detalle" :columns="columns_detalle" :options="options_detalle" class="elevation-2 pa-2">
                
                <template slot="existencia" slot-scope="props">
                  <div style="text-align: center;">
                    {{props.row.existencia}}
                  </div>
                </template>

              </v-client-table>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_detalle=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>


<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('s.inventarios.historico')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.getArticulos();
    this.sucursal_activa = this.$local_storage.get("sb_sucursal","0");
    if(this.sucursal_activa == "0"){ //Si en otra pestaña cambiaron a sucursal TODAS recargamos la pagina
        this.$router.go();            
    }
  },
  data() {
    return {
      articulos:[],
      sucursal_activa:"",
      menu_fecha:false,
      fecha_historico: "",
      modal_detalle: false,
      lote_detalle: [],
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      fab: [],
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Inventario",
          disabled: true,
          href: ""
        },
        {
          text: "Histórico",
          disabled: true,
          href: ""
        }
      ],
      encabezadoLayout: {
        "Código de Barras": "codigo_barras",
        "Artículo": "nombre_articulo",
        "Descripción": "descripcion",
        "Categoría": "categoria",
        "Marca/Modelo": "marca",
        "Tasa IVA": "tasa",
        "Exist. en Sucursal": "existencia_sucursal",
        "Exist. Total": "existencia_total",
        "Precio Compra S/Imp":"precio_compra",
        "Valor S/Imp":"valor",
        "Precio Compra C/Imp":"precio_compra_con_iva",
        "Valor C/Imp":"valor_con_iva",        
      },
      columns: [
        "codigo_barras", "nombre_articulo", "descripcion","categoria", "marca","tasa","existencia_sucursal","existencia_total",
        "precio_compra","valor","precio_compra_con_iva","valor_con_iva",
        "actions"
      ],
      columns_detalle: ["nombre", "existencia"],
      options_detalle: {
        filterable: ["nombre", "existencia"],
        sortable: ["nombre", "existencia"],

        headings: {
          nombre: "Sucursal",          
          existencia: "Existencia",
        },
      },
      options: {
        perPage: 20,
        pagination: {
          show: true
        },
        filterable: ["codigo_barras", "nombre_articulo", "descripcion","categoria", "marca","tasa","existencia_total","existencia_sucursal","precio_compra_con_iva","precio_compra","valor","valor_con_iva"],
        sortable: ["codigo_barras", "nombre_articulo", "descripcion","categoria", "marca","tasa","existencia_total","precio_compra_con_iva","precio_compra","valor","valor_con_iva"],

        headings: {
          codigo_barras: "Código de Barras",
          nombre_articulo: "Artículo",
          descripcion:"Descripción",
          categoria: "Categoría",
          "marca": "Marca/Modelo",
          "tasa":"Tasa IVA",
          "precio_compra":"Precio Compra S/Imp",
          "precio_compra_con_iva":"Precio Compra C/Imp",
          "valor":"Valor S/Imp",
          "valor_con_iva":"Valor C/Imp",
          existencia_total: "Exist. Total",
          existencia_sucursal:"Exist. en Sucursal",
          actions: "Detalle"
        },
      },
      modal: false,
      items: [],
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
    }
  },
  methods: {
    infiniteHandler: function($state) {
      if (this.fecha_historico == "" || this.fecha_historico == null) {
        $state.complete();
        return;
      }
      var rows_per_page = 10;
      var page = Math.ceil(this.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {"type":"articulos"};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
            if(x == "codigo_barras"){
              where[x] = {
                "$elemMatch": {
                  "$regex": "(?i)" + this.model_filters[x]
                }
              }
            }else if(x == "categoria"){
              where[x] = {
                "$elemMatch": {
                  "$regex": "(?i)" + this.model_filters[x]
                }
              }
            } else {
              where[x] = {
                "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
              }
            }
          
        }
      });

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where
          },
        }).then(async response => {
          if (response.data.docs.length) {
            let procesado = await this.procesar_articulos(response.data.docs)
            this.items = this.items.concat(procesado);
            this.props_paginacion.total_registros = this.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    formatNumberDecCero: function(numero, decimales){
          if (numero == undefined || numero == null || numero == "")
              numero = 0;
          numero = Number(numero);
          if (isNaN(numero)){
              return "";
          } else{
            if(parseFloat(numero) == parseInt(numero))
              return numero.toString().replace(/\d(?=(\d{3}))/g, '$&,');
            else
              return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
          }
              
      },
    formatNumberDec: function(numero, decimales){
          if (numero == undefined || numero == null || numero == "")
              return "0";
          numero = Number(numero);
          if (isNaN(numero)){
              return "0";
          } else
              return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      },
    procesar_articulos: async function(datos) {
      let ids = [];
      let sucursal = this.sucursal_activa;
      datos.forEach(x => {
        ids.push(x._id);
        x.total_inventario = 0;
        let datos_consulta = {
          "id_articulo": x._id,
          "id_sucursal": sucursal == 0 ? "" : sucursal
        };

        window.funciones_lotes.consultaExistenciaHistorico(datos_consulta, this.fecha_historico).then(result => {
          if (result.length > 0) {
            result.forEach(el => {
              x.total_inventario += el.existencia
            });
          } else {
            x.total_inventario += 0;
          }
          x.total_inventario = parseFloat(parseFloat(x.total_inventario).toFixed(2));

        }).catch(err => {
          if (err == "error_fecha"){
            return;
          }

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al consultar existencia de artículo.",
            footer: err
          });
        });
      });

      return datos;
    },
    get_detalle: function(sucursales) {
      
      this.lote_detalle = sucursales;
      this.modal_detalle = true;
         
    },
    get_sucursal: function(id) {
      let seleccionada = window.sucursales.filter(x => x._id == id);

      return seleccionada.length > 0 ? seleccionada[0].nombre : "No Encontrada";
    },
    parseDate(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    buscar: function() {
        if (this.fecha_historico == "" || this.fecha_historico == null) {
            return;
        }

        window.dialogLoader.show('Espere un momento por favor..');

        let where = {"type":"lotes_historico","fecha":this.fecha_historico};

        axios({
            method: 'POST',
            url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
            withCredentials: true,
            data: {
              selector: where,
              use_index: "_design/14c59baed2aa8efc1e822d9ae22e50872f9c27e7",
              limit: 1, //Debería haber solo un historico por fecha
            },
          }).then(async response => {
            if (response.data.docs.length) {
              this.items = response.data.docs[0].articulos;
              for(var k in this.items){

                this.items[k]["tasa"] = this.getIvaArticulo(this.items[k]["id_articulo"]);
                
                try {
                  this.items[k]["valor"] = parseFloat(this.items[k]["existencia_total"]) * parseFloat(this.items[k]["precio_compra"]);  
                  this.items[k]["precio_compra"] = parseFloat(this.items[k]["precio_compra"]);
                } catch (error) {
                  this.items[k]["valor"] = 0;
                }

                try {
                  this.items[k]["valor_con_iva"] = parseFloat(this.items[k]["existencia_total"]) * parseFloat(this.items[k]["precio_compra_con_iva"]);  
                  this.items[k]["precio_compra_con_iva"] = parseFloat(this.items[k]["precio_compra_con_iva"]);
                } catch (error) {
                  this.items[k]["valor_con_iva"] = 0;
                }
                
                this.items[k]["existencia_sucursal"] = 0;
                var suc = this.items[k].sucursales.find(e => e._id == this.sucursal_activa)
                if(suc){
                  this.items[k]["existencia_sucursal"] = parseFloat(parseFloat(suc.existencia).toFixed(2));
                }                
              }              
              
            } else {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "No hay inventario histórico en la fecha seleccionada. Intente Nuevamente.",
                footer: ""
              });
            }
          })
          .catch(error => {
            console.log(error)
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al buscar inventario histórico. Intente Nuevamente.",
                footer: err
              });
          }).then(()=>{
            window.dialogLoader.hide();
          });
    }, 
    getArticulos:function(){
        window.dialogLoader.show('Espere un momento por favor..');
            let data = {
                "selector": {
                    "type": "articulos",
                    "estatus":{ "$exists": true },
                    //"proveedores.0": { "$exists": true }
                },
                "fields":["_id","impuestos"],
                "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
            };
            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    this.articulos = response.data.docs;                    
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });
                }).then(()=>{
                  window.dialogLoader.hide();
                });
        },
        getIvaArticulo: function(id_articulo){
            var filtro = this.articulos.find(function(e){
                return e._id == id_articulo;
            });
            if(filtro){
                var iva = "";
                if(filtro["impuestos"]){
                  for(var k in filtro["impuestos"]){
                    if(filtro["impuestos"][k].clave == "002"){
                      iva = filtro["impuestos"][k].tasa;                      
                    }
                  }
                }
                return iva;
            } else {
                return "";
            }
        },
  }
}
</script>
